<template>
  <div class="Wrap">
    <img class="bg" src="../assets/images/homeBg.gif" alt="" />
    <div class="btn" @click="clickHandle">
      <img src="../assets/images/btn.png" alt="" />
      <div
        style="position: absolute; top: 0; left: 0; width: 65vw; height: 100%"
        v-html="xcxHTML"
      ></div>
    </div>

    <div class="tip" v-if="isTip">
      <div class="tipContent">
        <div class="wxImg">
          <img src="../assets/images/wx.png" alt="" />
        </div>
        <div class="tipText ph">
          请保存图片至手机，并使用微信扫一扫识别相册中此图进入小程序
        </div>
        <div class="tipText mh">请使用手机微信扫一扫识别进入小程序</div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import { getWeAppCode } from "@/util/index.js";
import axios from "axios";
export default {
  name: "Home",
  components: {},
  data() {
    return {
      xcxHTML: "",
      isTip: false,
      openLink: "",
    };
  },
  mounted() {
    this.xcxHTML = getWeAppCode();
    if (!this.isMobile()) {
      axios
        .get("https://roche.zitzolab.com/wechat/wechat/GetUrlScheme.php")
        // .get("https://rochelink.makebuluo.cn/wechat/wechat/GetUrlScheme.php")
        .then((data) => {
           eval('('+data.data+')')
          this.openLink = eval('('+data.data+')').openlink;
        });
    }
  },
  methods: {
    isMobile() {
      if (
        window.navigator.userAgent.match(
          /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
        )
      ) {
        // 移动端
        return false
      } else {
      // PC端
        this.isTip = true;
        return true
      }
    },
    clickHandle() {
              location.href = this.openLink;
    },
  },
};
</script>
<style lang="less" scoped>
.Wrap {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #eaeef4;
  .bg {
    // position: absolute;
    // left: 50%;
    // top: 50%;
    // transform: translate(-50%, -50%);
    height: 100vh;
    object-fit: cover;
  }
}
.btn {
  width: 270px;
  position: absolute;
  bottom: 7%;
  left: 50%;
  transform: translate(-50%, 0);
}

.tip {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
}
.tipContent {
  border-radius: 5px;
  width: 70vw;
  text-align: left;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3.5vw;
  background: #fff;
  .wxImg {
    width: 70%;
    margin: 3vw auto;
    margin-top: 5vw;
  }
  .tipText {
    padding: 3vw 5.5vw;
    width: 100%;
    box-sizing: border-box;
  }
  // padding: 5vw;
  // .tipBtnBox {
  //   margin-top: 1vw;
  //   width: 100%;
  //   border-radius: 10px;
  //     border: 1px solid #f2f2f2;
  //     font-size: 3vw;
  //  padding: 2vw 0;
  // }
}
.mh {
  display: none;
}
@media (min-width: 1000px) {
  .ph {
    display: none;
  }
  .mh {
    display: block;
  }
  .tipContent {
    width: 520px;
    font-size: 20px;

    .wxImg {
      width: 70%;
      margin: 20px auto;
      margin-top: 40px;
    }
    .tipText {
      padding: 20px 55px;
      text-align: center;
    }
  }
}
</style>